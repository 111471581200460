<h3>{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.HEADLINE' | translate }}</h3>

<p class="description">
  {{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.DESCRIPTION' | translate }}
</p>
<div class="row gap">
  <div class="col-6 download">
    <div>
      <h3 class="download-title">{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.DOWNLOAD.HEADING' | translate }}</h3>
      <p class="cx-file-uploader-description">{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.DOWNLOAD.DESCRIPTION' | translate }}</p>
      <a hpmSecureDownload [url]="getDownloadEndpoint()" fileName="externalPartners.json">
        <button color="accent" mat-flat-button>
          {{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.DOWNLOAD.BUTTON' | translate }}
        </button>
      </a>
    </div>
  </div>
  <div class="col-6">
    <cx-file-uploader
      [endpoint]="getUploadEndpoint()"
      acceptedFileTypes="application/json"
      buttonText="{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.UPLOAD.BUTTON' | translate }}"
      description="{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.UPLOAD.DESCRIPTION' | translate }}"
      errorMessage="{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.UPLOAD.ERROR_MESSAGE' | translate }}"
      heading="{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.UPLOAD.HEADING' | translate }}">
    </cx-file-uploader>
  </div>
</div>


<h3>{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.HEADLINE' | translate }}</h3>

<p class="description">
  {{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.DESCRIPTION' | translate }}
</p>

<div class="logo-list row">
  <button mat-icon-button (click)="toggleListOpen()" class="list-toggle-button">
    @if (listOpen) {
      <mat-icon>keyboard_arrow_up</mat-icon>
    } @else {
      <mat-icon>keyboard_arrow_down</mat-icon>
    }
  </button>
  <cx-list class="col-7" [listTitle]="'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.LIST'|translate">
    @for (partner of visiblePartners; track partner) {
      <cx-list-item [itemTitle]="partner.name ? partner.name : partner.siteId" [subtitle]="getSubtitle(partner)">
        <cx-image-thumbnail [imgSrc]="getLogoPath(partner.siteId)"></cx-image-thumbnail>
        <button class="cx-icon-stroked-button" mat-icon-button (click)="selectPartner(partner)">
          <mat-icon class="material-symbols-outlined">upload</mat-icon>
        </button>
        <button class="cx-icon-stroked-button" mat-icon-button
                [hpmConfirmDelete]="'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.DELETE_MESSAGE' | translate: { siteId: partner.siteId }" (confirmation)="deletePartnerLogo($event, partner.siteId)">
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
        </button>
      </cx-list-item>
    }
  </cx-list>
</div>

<ng-template #updateDialog>
  @if (selectedPartner) {
    Logo für SiteId {{ selectedPartner.siteId }}
  }
  <div class="logo-preview">
    @if (selectedPartner) {
      <cx-image [maxWidth]="'11rem'" [imgSrc]="getLogoPath(selectedPartner.siteId)"></cx-image>
    }
    @if (selectedPartner && uploadedLogo) {
      <div>{{ 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.REPLACE_BY' | translate }}</div>
    }
    @if (uploadedLogo) {
      <img [src]="uploadedLogo" [alt]="'new Uploaded Logo'">
    }
  </div>


  <cx-file-uploader
    [heading]=" 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.HEADLINE' | translate"
    [description]=" 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.DESCRIPTION' | translate"
    [buttonText]=" 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.BUTTON' | translate"
    [errorMessage]=" 'ADMIN.EXTERNAL_PARTNER_UPDATE.LOGO.UPLOAD_DIALOG.ERROR_MESSAGE' | translate"
    [acceptedFileTypes]="'image/jpeg'"
    [manualUpload]="true"
    [files]="files"
    (selectFile)="fileSelected($event)"
    (removeFile)="fileRemoved($event)"
  ></cx-file-uploader>

</ng-template>
